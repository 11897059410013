import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

//@ts-ignore
import AFRAME from 'aframe'

const THREE = AFRAME.THREE

AFRAME.registerComponent('unlit', {

  init: function () {

      function recursivelySetChildrenUnlit(mesh : any) {

          if (mesh.material && mesh.material.map) {
              mesh.material = new THREE.MeshBasicMaterial({ map: mesh.material.map });
          }

          if (mesh.children) {
              for (let i = 0; i < mesh.children.length; i++) {
                  recursivelySetChildrenUnlit(mesh.children[i]);
              }
          }
      }

      this.el.addEventListener('model-loaded', (e : any) => {

          const mesh = e.target.getObject3D('mesh');
          for (let i = 0; i < mesh.children.length; i++) {
              recursivelySetChildrenUnlit(mesh.children[i]);
          }

      });
  }
});


AFRAME.registerComponent('brightness', {
  init: function () { 
    this.el.renderer.gammaOutput = true
    this.el.renderer.outputEncoding = THREE.sRGBEncoding
  }
});

AFRAME.registerComponent('srgb', {
  init: function () { 
    this.el.components.material.shader.material.map.encoding  = THREE.sRGBEncoding
  }
});

Vue.config.productionTip = false

Vue.config.ignoredElements = [
  'a-scene',
  'a-camera',
  'a-assets',
  'a-asset-item',
  'a-gltf-model',
  'a-entity',
  'a-video',
  'a-sphere',
  'a-curvedimage'
]

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
