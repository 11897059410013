



















































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class App extends Vue 
{
  showButton = true;

  onClick()
  {
    this.showButton = false;
    //@ts-ignore
    this.$refs['wedding-video'].play()
  }

  getDisplay()
  {
    if( this.showButton )
    {
      return "display: block;"
    }
      return "display: inline;"    
  }
}
